import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { Sky } from 'three/addons'
import { Timer } from 'three/addons/misc/Timer.js'
import GUI from 'lil-gui'

/**
 * Base
 */
// Debug
const gui = new GUI()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const configFloorTexture = (texture) => {
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(6,6)
}
// Floor
const floorAlphaTexture = textureLoader.load('./floor/forest_ground_04/alpha.jpg')
const floorColorTexture = textureLoader.load('./floor/forest_ground_04/forest_ground_04_diff_1k.avif')
const floorARMTexture = textureLoader.load('./floor/forest_ground_04/forest_ground_04_arm_1k.avif')
const floorNormalTexture = textureLoader.load('./floor/forest_ground_04/forest_ground_04_nor_gl_1k.webp')
const floorDisplacementTexture = textureLoader.load('./floor/forest_ground_04/forest_ground_04_disp_1k.avif')
floorColorTexture.colorSpace = THREE.SRGBColorSpace
configFloorTexture(floorColorTexture)
configFloorTexture(floorARMTexture)
configFloorTexture(floorNormalTexture)
configFloorTexture(floorDisplacementTexture)

const wallColorTexture = textureLoader.load('./wall/rock_wall_09/rock_wall_09_diff_1k.avif')
const wallARMTexture = textureLoader.load('./wall/rock_wall_09/rock_wall_09_arm_1k.avif')
const wallNormalTexture = textureLoader.load('./wall/rock_wall_09/rock_wall_09_nor_gl_1k.avif')
wallColorTexture.colorSpace = THREE.SRGBColorSpace
const configWallTexture = (texture) => {
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(2,2)
}
configWallTexture(wallColorTexture)
configWallTexture(wallARMTexture)
configWallTexture(wallNormalTexture)

const roofColorTexture = textureLoader.load('./roof/roof_07/roof_07_diff_1k.avif')
const roofARMTexture = textureLoader.load('./roof/roof_07/roof_07_arm_1k.avif')
const roofNormalTexture = textureLoader.load('./roof/roof_07/roof_07_nor_gl_1k.avif')
roofColorTexture.colorSpace = THREE.SRGBColorSpace
const configRoofTexture = (texture) => {
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(4,2)
}
configRoofTexture(roofColorTexture)
configRoofTexture(roofARMTexture)
configRoofTexture(roofNormalTexture)

const bushColorTexture = textureLoader.load('./bush/forest_leaves_03/forest_leaves_03_diff_1k.webp')
const bushARMTexture = textureLoader.load('./bush/forest_leaves_03/forest_leaves_03_arm_1k.avif')
const bushNormalTexture = textureLoader.load('./bush/forest_leaves_03/forest_leaves_03_nor_gl_1k.avif')
const bushDisplacementTexture = textureLoader.load('./bush/forest_leaves_03/forest_leaves_03_disp_1k.avif')
bushColorTexture.colorSpace = THREE.SRGBColorSpace

const configBushTexture = (texture) => {
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(2,2)
}
configBushTexture(bushColorTexture)
configBushTexture(bushARMTexture)
configBushTexture(bushNormalTexture)
configBushTexture(bushDisplacementTexture)

const graveColorTexture = textureLoader.load('./grave/plastered_stone_wall/plastered_stone_wall_diff_1k.webp')
const graveARMTexture = textureLoader.load('./grave/plastered_stone_wall/plastered_stone_wall_arm_1k.webp')
const graveNormalTexture = textureLoader.load('./grave/plastered_stone_wall/plastered_stone_wall_nor_gl_1k.webp')
graveColorTexture.colorSpace = THREE.SRGBColorSpace

const doorColorTexture = textureLoader.load("./door/color.webp")
const doorAlphaTexture = textureLoader.load("./door/alpha.webp")
const doorAmbientOcclusionTexture = textureLoader.load("./door/ambientOcclusion.webp")
const doorHeightTexture = textureLoader.load("./door/height.jpg")
const doorMetalnessTexture = textureLoader.load("./door/metalness.webp")
const doorNormalTexture = textureLoader.load("./door/normal.jpg")
const doorRoughnessTexture = textureLoader.load("./door/roughness.jpg")
doorColorTexture.colorSpace = THREE.SRGBColorSpace


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Floor
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(30, 30, 250, 250),
    new THREE.MeshStandardMaterial({
        alphaMap: floorAlphaTexture,
        aoMap: floorARMTexture,
        roughnessMap: floorARMTexture,
        metalnessMap: floorARMTexture,
        normalMap: floorNormalTexture,
        transparent: true,
        map: floorColorTexture,
        displacementMap: floorDisplacementTexture,
        displacementBias : -0.3
    })
)
floor.rotation.x = - Math.PI / 2
scene.add(floor)

gui.add(floor.material,
    'displacementScale',)
    .min(0)
    .max(1)
    .step(0.001)
    .name('floorDisplacementScale')

gui.add(floor.material,
'displacementBias')
    .min(-1)
    .max(1)
    .step(0.001)
    .name('floorDisplacementBias')

/**
 * House
 */
const house = new THREE.Group()
scene.add(house)

const walls = new THREE.Mesh(
    new THREE.BoxGeometry(4,3,4),
    new THREE.MeshStandardMaterial({
        map: wallColorTexture,
        aoMap: wallARMTexture,
        roughnessMap: wallARMTexture,
        metalnessMap: wallARMTexture,
        normalMap: wallNormalTexture
    })
)
walls.position.y = 1.5
house.add(walls)


const roof = new THREE.Mesh(
    new THREE.ConeGeometry(3,2,4),
    new THREE.MeshStandardMaterial({
        map: roofColorTexture,
        aoMap: roofARMTexture,
        roughnessMap: roofARMTexture,
        metalnessMap: roofARMTexture,
        normalMap: roofNormalTexture,
    })
)
roof.position.y = 4
roof.rotation.y = Math.PI / 4
house.add(roof)

const door = new THREE.Mesh(
    new THREE.PlaneGeometry(2.4,2.4,2.2, 100, 100),
    new THREE.MeshStandardMaterial({
        metalness: 1,
        map: doorColorTexture,
        aoMap: doorAmbientOcclusionTexture,
        aoMapIntensity: 1,
        displacementMap: doorHeightTexture,
        displacementScale: 0.05,
        displacementBias: -0.0025,
        metalnessMap: doorMetalnessTexture,
        roughnessMap: doorRoughnessTexture,
        normalMap: doorNormalTexture,
        transparent: true,
        alphaMap: doorAlphaTexture
    })
)
door.position.y = 1.2
door.position.z = 2 + 0.001
house.add(door)

const bushGeometry = new THREE.SphereGeometry(1,16,16)
const bushMaterial = new THREE.MeshStandardMaterial({
    color: '#ccddcc',
    map: bushColorTexture,
    aoMap: bushARMTexture,
    roughnessMap: bushARMTexture,
    metalnessMap: bushARMTexture,
    normalMap: bushNormalTexture,
    displacementMap: bushDisplacementTexture,
    displacementScale: 0.4
})

const bush1 = new THREE.Mesh(
    bushGeometry,
    bushMaterial
)
bush1.rotation.x = - 0.75
bush1.rotation.z = 0.5
bush1.position.set(2.3, 0.3,2.3)
bush1.scale.set(0.6,0.6,0.6)
house.add(bush1)

const bush2 = new THREE.Mesh(
    bushGeometry,
    bushMaterial
)
bush2.rotation.x = - 0.75
bush2.position.set(1.5, 0.2,2.3)
bush2.scale.set(0.5,0.5,0.5)
house.add(bush2)

const bush3 = new THREE.Mesh(
    bushGeometry,
    bushMaterial
)
bush3.rotation.x = - 1
bush3.rotation.z = - 0.5
bush3.position.set(-2.3, 0.2,2.3)
bush3.scale.set(0.8,0.8,0.8)
house.add(bush3)

const bush4 = new THREE.Mesh(
    bushGeometry,
    bushMaterial
)
bush4.rotation.x = - 0.75
bush4.rotation.z = 0.75
bush4.position.set(-1.4, 0.2,2.8)
bush4.scale.set(0.4,0.4,0.4)
house.add(bush4)

// Graves
const graveGeometry = new THREE.BoxGeometry(0.6,0.8,0.2)
const graveMaterial = new THREE.MeshStandardMaterial({
    map: graveColorTexture,
    aoMap: graveARMTexture,
    roughnessMap: graveARMTexture,
    metalnessMap: graveARMTexture,
    normalMap: graveNormalTexture
})

const graves = new THREE.Group()
scene.add(graves)

for (let i = 0; i < 30; i++) {
    const angle = Math.random() * Math.PI * 2
    const radius = 4.5 + Math.random() * 4
    const grave = new THREE.Mesh(
        graveGeometry,
        graveMaterial
    )
    grave.position.x = Math.sin(angle) * radius
    grave.position.y = (0.1 + Math.random()) * 0.4
    grave.position.z = Math.cos(angle) * radius
    grave.rotation.x = (Math.random() - 0.5) * 0.4
    grave.rotation.y = (Math.random() - 0.5) * 0.4
    grave.rotation.z = (Math.random() - 0.5) * 0.4
    graves.add(grave)
}


/**
 * Lights
 */
// Ambient light
const ambientLight = new THREE.AmbientLight('#86cdff', 0.275)
scene.add(ambientLight)

// Directional light
const directionalLight = new THREE.DirectionalLight('#86cdff', 1)
directionalLight.position.set(3, 2, -8)
scene.add(directionalLight)

const doorLight = new THREE.PointLight('#ff7d46', 5)
doorLight.position.set(0, 2.6, 2.3)
house.add(doorLight)

/**
 * Ghosts
 */
const ghost1 = new THREE.PointLight('#8800ff', 6)
const ghost2 = new THREE.PointLight('#ff0088', 6)
const ghost3 = new THREE.PointLight('#ff0000', 6)
scene.add(ghost1,ghost2, ghost3)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 4
camera.position.y = 2
camera.position.z = 5
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Shadows
 */
// Renderer
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap

// cast and receive
directionalLight.castShadow = true
ghost1.castShadow = true
ghost2.castShadow = true
ghost3.castShadow = true

walls.castShadow = true
walls.receiveShadow = true
roof.castShadow = true
floor.receiveShadow = true

for(const grave of graves.children) {
    grave.castShadow = true
    grave.receiveShadow = true
}

// Mappings
directionalLight.shadow.mapSize.width = 256
directionalLight.shadow.mapSize.height = 256
directionalLight.shadow.camera.top = 8
directionalLight.shadow.camera.right = 8
directionalLight.shadow.camera.bottom = - 8
directionalLight.shadow.camera.left = - 8
directionalLight.shadow.camera.near = 1
directionalLight.shadow.camera.far = 20

ghost1.shadow.mapSize.width = 256
ghost1.shadow.mapSize.height = 256
ghost1.shadow.camera.far = 10

ghost2.shadow.mapSize.width = 256
ghost2.shadow.mapSize.height = 256
ghost2.shadow.camera.far = 10

ghost3.shadow.mapSize.width = 256
ghost3.shadow.mapSize.height = 256
ghost3.shadow.camera.far = 10

/**
 * Sky
 */
const sky = new Sky()
sky.scale.set(100, 100, 100)
scene.add(sky)

sky.material.uniforms['turbidity'].value = 10
sky.material.uniforms['rayleigh'].value = 3
sky.material.uniforms['mieCoefficient'].value = 0.1
sky.material.uniforms['mieDirectionalG'].value = 0.95
sky.material.uniforms['sunPosition'].value.set(0.3, -0.038, -0.95)

/**
 * Fog
 */
scene.fog = new THREE.FogExp2('#04343f', 0.1)

/**
 * Animate
 */
const timer = new Timer()

const tick = () =>
{
    // Timer
    timer.update()
    const elapsedTime = timer.getElapsed()

    // Update controls
    controls.update()

    // Update ghost position
    const ghost1Angle = elapsedTime * 0.5
    ghost1.position.x = Math.cos(ghost1Angle) * 4
    ghost1.position.z = Math.sin(ghost1Angle) * 4
    ghost1.position.y = Math.sin(ghost1Angle) * Math.sin(ghost1Angle * 2.34) * Math.sin(ghost1Angle * 3.45)

    const ghost2Angle = - elapsedTime * 0.38
    ghost2.position.x = Math.cos(ghost2Angle) * 5
    ghost2.position.z = Math.sin(ghost2Angle) * 5
    ghost2.position.y = Math.sin(ghost2Angle) * Math.sin(ghost2Angle * 2.34) * Math.sin(ghost2Angle * 3.45)

    const ghost3Angle = elapsedTime * 0.23
    ghost3.position.x = Math.cos(ghost3Angle) * 5
    ghost3.position.z = Math.sin(ghost3Angle) * 5
    ghost3.position.y = Math.sin(ghost3Angle) * Math.sin(ghost3Angle * 4.2) * Math.sin(ghost3Angle * 1.82)

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()